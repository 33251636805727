import OutputText from '../form/outputText/OutputText.vue'

export default {
    name: 'TextList',

    components: {
        OutputText
    },

    props: {
        items: {
            type: Array,
            required: true
        }
    },

    data() {
        return {}
    },

    methods: {}
}
