export default {
    name: 'OutputText',

    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String
        },
        error: {
            type: Boolean,
            default: false
        }
    }
}
