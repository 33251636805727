import stepMixin from '../../../mixins/stepMixin'
import customerMixin from '../../../mixins/customerMixin'
import FormCheck from '../../form/formCheck/FormCheck.vue'
import FormInput from '../../form/formInput/FormInput.vue'
import FormTextarea from '../../form/formTextarea/FormTextarea.vue'
import TextList from '../../textList/TextList.vue'
import EventBus from '../../../utils/eventBus'
import { dispoTeamPhoneNumber } from '../../../constants/general'

export default {
    name: 'Authority',

    props: {
        stepIsValid: {
            type: Boolean,
            required: true
        }
    },

    mixins: [stepMixin, customerMixin],

    components: {
        FormCheck,
        FormInput,
        FormTextarea,
        TextList
    },

    data() {
        return {
          dispoTeamPhoneNumber
        }
    },

    created() {        
        // Inititally set contractPartnerOnSite
        EventBus.$emit('setForm', {
            key: 'contractPartnerOnSite',
            value: this.contractPartnerOnSite,
            step: 'authority'
        })
    },

    methods: {
        validate() {
            let isValid = true

            if (
                this.field.alternativePerson &&
                (!this.field.alternativePersonLastName ||
                    !this.field.alternativePersonFirstName ||
                    !this.field.alternativePersonAddressStreet ||
                    !this.field.alternativePersonAddressZip ||
                    !this.field.alternativePersonAddressCity)
            ) {
                isValid = false
            }

            this.$emit('update:stepIsValid', isValid)
        }
    },

    watch: {
        'field.alternativePerson': function(value) {
            if (!value) {
                // Reset alternative person fields
                EventBus.$emit('setForm', {
                    key: 'alternativePersonLastName',
                    value: null,
                    step: 'authority'
                })
                EventBus.$emit('setForm', {
                    key: 'alternativePersonFirstName',
                    value: null,
                    step: 'authority'
                })
                EventBus.$emit('setForm', {
                    key: 'alternativePersonAddressStreet',
                    value: null,
                    step: 'authority'
                })
                EventBus.$emit('setForm', {
                    key: 'alternativePersonAddressZip',
                    value: null,
                    step: 'authority'
                })
                EventBus.$emit('setForm', {
                    key: 'alternativePersonAddressCity',
                    value: null,
                    step: 'authority'
                })
            }
        }
    }
}
